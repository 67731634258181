import React, { createElement } from 'react';
import { memoizeWith, identity } from 'ramda';
import { cn } from '@superside/ui';
import { composeStyles } from '../../utils';
import theme from '../../theme/theme';
import {
  type TextAlign,
  useHeadingSizeStyles,
  useTextAlignStyles,
  useMarginStyles,
  useMaxWidthStyles,
  useColorStyles
} from '../../styles';
import { omitStyleProps } from '../Box';
import { inlineStyles, semiBoldStyles } from '../Text/textStyles';
import { baseHeadingStyles, underlineStyles } from './headingStyles';

export interface HeadingProps {
  semibold?: boolean;
  underline?: boolean;
  maxWidth?: string;
  level?: '1' | '2' | '3' | '4' | '5' | '6';
  size?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  className?: string;
  textAlign?: TextAlign;
  position?;
  responsive?;
  margin?;
  color?;
  inline?: boolean;
  ref?: any;
  weight?: string | number;
  dangerouslySetInnerHTML?: { __html: string };
  style?;
  title?;
  children?: any;
}

const useStyles = composeStyles(
  useMarginStyles,
  useMaxWidthStyles,
  useHeadingSizeStyles,
  useTextAlignStyles,
  useColorStyles
);

export const Heading: React.FC<HeadingProps> = React.forwardRef((props: any, ref) => {
  const {
    children,
    level = '2',
    margin,
    maxWidth,
    position,
    size = '6',
    textAlign,
    weight,
    color,
    semibold = false,
    underline = false,
    responsive = true,
    inline = false,
    ...rest
  } = props;

  const { className, style } = useStyles(
    {
      level,
      margin,
      maxWidth,
      position,
      responsive,
      size,
      textAlign,
      weight,
      color
    },
    theme
  );

  const finalProps = {
    ...omitStyleProps(rest),
    ref,
    style,
    className: cn(
      'font-heading',
      baseHeadingStyles,
      className,
      semibold && semiBoldStyles,
      underline && underlineStyles,
      inline && inlineStyles,
      props.className
    )
  };

  return createElement(getTagName(level), finalProps, children);
});

Heading.displayName = 'Heading';

Heading.defaultProps = {
  level: '2',
  size: '6'
};

const levels = [1, 2, 3, 4, 5, 6];

const getTagName = memoizeWith(identity, (level) => {
  if (levels.includes(parseInt(level))) {
    return `h${level}`;
  }

  return 'h6';
});

export default Heading;
