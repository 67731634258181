import { headingSizeVars } from '../theme/heading';

const headingStyles = {
  1: 'text-h1',
  2: 'text-h2',
  3: 'text-h3',
  4: 'text-h4',
  5: 'text-h5',
  6: 'text-h6',
  7: 'text-h7',
  8: 'text-h8',
  9: 'text-h9'
};

const getHeadingSize = (size: any) => (headingSizeVars[size] ? headingSizeVars[size].val : size);

export const useHeadingSizeStyles = (props) => {
  const { size = 6 } = props;
  const finalStyles = {
    className: [],
    style: {}
  };

  if (headingSizeVars[size]) {
    finalStyles.className.push(headingStyles[size]);
    finalStyles.style['--font-size-d179'] = getHeadingSize(size);
  }

  return finalStyles;
};
