export const findVisibleParent = (element) => {
  if (element) {
    return element.offsetParent ? element : findVisibleParent(element.parentElement) || element;
  }

  return undefined;
};

const documentTags = ['html', 'body'];

export const findScrollParents = (element) => {
  const result = [];

  if (element) {
    let parent = element.parentNode;

    while (parent && parent.getBoundingClientRect) {
      parent = parent.parentNode;
    }
    if (result.length === 0) {
      result.push(document);
    } else if (documentTags.includes(result[0].tagName.toLowerCase())) {
      result.length = 0;
      result.push(document);
    }
  }

  return result;
};
