import { ralewayFontFamily } from './font';
import { textSizeWithCssProps as textSize } from './text-size';

export const heading = {
  font: {
    family: ralewayFontFamily
  },
  level: {
    1: {
      display: {
        small: {
          size: '36px',
          height: '46px',
          maxWidth: '880px'
        },
        medium: {
          size: '52px',
          height: '64px',
          maxWidth: '880px'
        },
        large: {
          size: '64px',
          height: '88px',
          maxWidth: '880px'
        }
      },
      normal: {
        xxsmall: {
          size: '22px',
          height: '30px',
          maxWidth: '880px'
        },
        xsmall: {
          size: '32px',
          height: '42px',
          maxWidth: '880px'
        },
        small: {
          size: '36px',
          height: '46px',
          maxWidth: '880px'
        },
        medium: {
          size: '44px',
          height: '57px',
          maxWidth: '880px'
        },
        large: {
          size: '52px',
          height: '64px',
          maxWidth: '880px'
        }
      }
    },
    2: {
      normal: {
        xxsmall: {
          ...textSize.medium,
          maxWidth: '100%'
        },
        xsmall: {
          size: '22px',
          height: '30px',
          maxWidth: '880px'
        },
        small: {
          size: '26px',
          height: '34px',
          maxWidth: '880px'
        },
        medium: {
          size: '32px',
          height: '41px',
          maxWidth: '880px'
        },
        large: {
          size: '44px',
          height: '57px',
          maxWidth: '880px'
        }
      }
    },
    3: {
      normal: {
        xsmall: {
          ...textSize.large,
          maxWidth: '100%'
        },
        small: {
          size: '24px',
          height: '36px',
          maxWidth: '100%'
        },
        medium: {
          size: '24px',
          height: '36px',
          maxWidth: '100%'
        },
        large: {
          size: '32px',
          height: '41px',
          maxWidth: '100%'
        }
      }
    },
    4: {
      normal: {
        xsmall: {
          ...textSize.medium,
          maxWidth: '100%'
        },
        small: {
          ...textSize.large,
          maxWidth: '100%'
        },
        medium: {
          ...textSize.xlarge,
          maxWidth: '100%'
        },
        large: {
          ...textSize.xxlarge,
          maxWidth: '100%'
        }
      }
    },
    5: {
      normal: {
        small: {
          ...textSize.medium,
          maxWidth: '100%'
        },
        medium: {
          ...textSize.large,
          maxWidth: '100%'
        },
        large: {
          ...textSize.xlarge,
          maxWidth: '100%'
        }
      }
    },
    6: {
      normal: {
        small: {
          ...textSize.small,
          maxWidth: '100%'
        },
        medium: {
          ...textSize.medium,
          maxWidth: '100%'
        },
        large: {
          ...textSize.large,
          maxWidth: '100%'
        }
      }
    }
  },
  responsiveBreakpoint: 'small',
  weight: 600
};

export type HeadingSize = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

export const headingSizeVars = {
  1: { name: '--h1', val: 'var(--h1)' },
  2: { name: '--h2', val: 'var(--h2)' },
  3: { name: '--h3', val: 'var(--h3)' },
  4: { name: '--h4', val: 'var(--h4)' },
  5: { name: '--h5', val: 'var(--h5)' },
  6: { name: '--h6', val: 'var(--h6)' },
  7: { name: '--h7', val: 'var(--h7)' },
  8: { name: '--h8', val: 'var(--h8)' },
  9: { name: '--h9', val: 'var(--h9)' }
};

export const headingSize = {
  1: '16px',
  2: '19px',
  3: '23px',
  4: '28px',
  5: '33px',
  6: '40px',
  7: '48px',
  8: '57px',
  9: '69px'
};

export const mediumHeadingSize = {
  1: '16px',
  2: '19px',
  3: '23px',
  4: '26px',
  5: '30px',
  6: '34px',
  7: '40px',
  8: '48px',
  9: '56px'
};

export const smallHeadingSize = {
  1: '16px',
  2: '18px',
  3: '20px',
  4: '23px',
  5: '26px',
  6: '30px',
  7: '34px',
  8: '40px',
  9: '46px'
};
