import React, { forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { LayerContainer } from './LayerContainer';

const Layer = forwardRef((props, ref) => {
  const [layerContainer, setLayerContainer] = useState();

  useEffect(() => {
    const container = document.createElement('div');
    const target = document.body;

    target.appendChild(container);
    setLayerContainer(container);
  }, []);

  return layerContainer
    ? createPortal(<LayerContainer ref={ref} {...props} />, layerContainer)
    : null;
});

export { Layer };
