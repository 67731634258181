import React, { forwardRef } from 'react';
import { cn } from '@superside/ui';
import styles from './StyledLayer.module.css';
import { getPositionStyles } from './layerStyles';

const StyledLayer: React.FC<{
  targetBounds?: any;
  className?: string;
  position?: string;
  responsive?: boolean;
  children?: any;
}> = forwardRef((props, ref) => (
  <div
    ref={ref as any}
    style={{
      // @ts-ignore
      '--top-var-8f2d': props.targetBounds?.top || '0',
      '--bottom-var-25a6': props.targetBounds?.bottom || '0',
      '--left-var-6df4': props.targetBounds?.left || '0',
      '--right-var-3247': props.targetBounds?.right || '0'
    }}
    className={cn(
      props.className,
      `${styles.layerStyle} pointer-events-none z-20 box-border text-base leading-normal`,
      props.position === 'hidden'
        ? 'fixed -left-full right-full z-[-1]'
        : props.targetBounds
        ? 'fixed bottom-[var(--bottom-var-25a6)] left-[var(--left-var-6df4)] right-[var(--right-var-3247)] top-[var(--top-var-8f2d)]'
        : 'fixed inset-0',
      props.responsive &&
        'max-[576px]:absolute max-[576px]:h-full max-[576px]:min-h-screen max-[576px]:w-full'
    )}
  >
    {props.children}
  </div>
));

StyledLayer.displayName = 'StyledLayer';

const StyledOverlay = (props: any) => (
  <div
    style={{ ...props.style }}
    className={cn(
      props.className,
      `${styles.overlayStyle} !fixed inset-0 z-[19]`,
      props.responsive ? 'max-[576px]:relative' : ''
    )}
  />
);

const animationDuration = 200;

const StyledContainer = (props: any) => {
  const isModal = props.modal === true;
  const isHidden = props.position === 'hidden';

  return (
    <div
      style={{
        ...props.style,
        '--position-var-ca7c': props.modal ? 'absolute' : 'fixed'
      }}
      className={cn(
        props.className,
        'max-h-[calc(100%_-_var(--top-bounds-var-76a2)_-_var(--bottom-bounds-var-2610))] max-w-[calc(100%_-_var(--left-bounds-var-c957)_-_var(--right-bounds-var-b558))] rounded-none',
        `${styles.containerStyle} z-[15] flex min-h-[48px] flex-col`,
        !isModal && `${styles.containerBaseStyle} box-border`,
        !isModal && isHidden ? 'fixed -left-full right-full z-[-1]' : '',
        !isHidden && getPositionStyles(props.position, props.full),
        props?.animation === 'fadeIn' && 'animate-fadeIn',
        props.responsive &&
          `${styles.responsiveContainerStyle} max-[576px]:relative max-[576px]:inset-0 max-[576px]:h-screen max-[576px]:max-h-[none] max-[576px]:w-screen max-[576px]:max-w-none max-[576px]:animate-none max-[576px]:rounded-none`
      )}
    >
      {props.children}
    </div>
  );
};

export { animationDuration, StyledLayer, StyledOverlay, StyledContainer };
