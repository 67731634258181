const basePositionStyles = {
  top: 'top-0',
  left: 'left-0',
  right: 'right-0',
  bottom: 'bottom-0',
  center: 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
  verticalCenter: 'top-1/2 -translate-y-1/2',
  horizontalCenter: 'left-1/2 -translate-x-1/2'
};

const positionStyles: any = {
  center: [basePositionStyles.center],
  bottom: [basePositionStyles.horizontalCenter, basePositionStyles.bottom],
  'bottom-left': [basePositionStyles.bottom, basePositionStyles.left],
  'bottom-right': [basePositionStyles.bottom, basePositionStyles.right],
  end: [basePositionStyles.right],
  left: [basePositionStyles.verticalCenter, basePositionStyles.left],
  right: [basePositionStyles.verticalCenter, basePositionStyles.right],
  start: [basePositionStyles.left],
  top: [basePositionStyles.horizontalCenter, basePositionStyles.top],
  'top-left': [basePositionStyles.top, basePositionStyles.left],
  'top-right': [basePositionStyles.top, basePositionStyles.right]
};

const baseFullStyles: any = {
  vertical: 'h-full',
  horizontal: 'w-full'
};

const fullStyles: any = {
  vertical: [baseFullStyles.vertical],
  horizontal: [baseFullStyles.horizontal],
  true: [baseFullStyles.vertical, baseFullStyles.horizontal],
  false: []
};

const animations = {
  zoomIn: {
    base: 'animation-zoom-in-base',
    center: 'animation-zoom-in-center',
    horizontal: 'animation-zoom-in-horizontal',
    vertical: 'animation-zoom-in-vertical'
  },
  slideDown: {
    base: 'animation-slide-down-base',
    center: 'animation-slide-down-center'
  },
  slideUp: {
    base: 'animation-slide-up-base',
    center: 'animation-slide-up-center'
  },
  slideRight: {
    base: 'animation-slide-right-base',
    center: 'animation-slide-right-center'
  },
  slideLeft: {
    base: 'animation-slide-left-base',
    center: 'animation-slide-left-center'
  }
};

const getFinalPosition = (positionProp: any, fullProp: any) => {
  const position = positionProp.toString();
  const full = fullProp.toString();

  switch (position) {
    case 'center':
      if (full === 'vertical') {
        return ['top', full];
      }
      if (full === 'horizontal') {
        return ['left', full];
      }
      if (full === 'true') {
        return ['top-left', full];
      }

      return ['center', full];
    case 'bottom':
      if (full === 'horizontal') {
        return ['bottom-left', full];
      }

      return ['bottom', full];
    case 'top':
      if (full === 'horizontal') {
        return ['top-left', full];
      }

      return ['top', full];
    default:
      return [position, full];
  }
};

const getAnimation = (position: any, full: any) => {
  switch (position) {
    case 'center':
      if (full === 'vertical') {
        return animations.zoomIn.horizontal;
      }
      if (full === 'horizontal') {
        return animations.zoomIn.vertical;
      }
      if (full === 'true') {
        return animations.zoomIn.base;
      }

      return animations.zoomIn.center;
    case 'top':
      return animations.slideDown.center;
    case 'top-left':
    case 'top-right':
      return animations.slideDown.base;
    case 'right':
      return animations.slideLeft.center;
    case 'left':
      return animations.slideRight.center;
    case 'bottom':
      return animations.slideUp.center;
    case 'bottom-left':
    case 'bottom-right':
      return animations.slideUp.base;
    default:
      return null;
  }
};

export const getPositionStyles = (positionProp: any, fullProp: any) => {
  const [position, full] = getFinalPosition(positionProp, fullProp);

  const classNames = ['-outline-offset-8'];

  if (positionStyles[position]) {
    classNames.push(...positionStyles[position]);
  }

  if (fullStyles[full]) {
    classNames.push(...fullStyles[full]);
  }

  const animation = getAnimation(positionProp.toString(), full);

  if (animation) {
    classNames.push(animation);
  }

  return classNames;
};
