import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { findVisibleParent } from '../../utils';
import { StyledLayer, StyledContainer, StyledOverlay } from './StyledLayer';

const fullBounds = { left: 0, right: 0, top: 0, bottom: 0 };

const LayerContainer = forwardRef(
  (
    {
      children,
      full = false,
      id,
      margin = 'none',
      modal = true,
      onClickOutside,
      // onEsc,
      plain,
      position = 'center',
      responsive = true,
      target: layerTarget,
      background,
      ...rest
    },
    ref
  ) => {
    const [targetBounds, setTargetBounds] = useState(fullBounds);
    const anchorRef = useRef();
    const containerRef = useRef();
    const layerRef = useRef();

    useEffect(() => {
      if (position !== 'hidden') {
        const node = layerRef.current || containerRef.current || ref?.current;

        node?.scrollIntoView?.();
        // Once layer is open we make sure it has focus so that you
        // can start tabbing inside the layer. If the caller put focus
        // on an element already, we honor that. Otherwise, we put
        // the focus in the hidden anchor.
        let element = document.activeElement;

        while (element) {
          if (element === containerRef.current) {
            // already have focus inside the container
            break;
          }
          element = element.parentElement;
        }
        if (modal && !element && anchorRef.current) {
          anchorRef.current.focus();
        }
      }
    }, [modal, position, ref]);

    useEffect(() => {
      if (layerTarget) {
        const updateBounds = () => {
          const rect = findVisibleParent(layerTarget).getBoundingClientRect();

          setTargetBounds({
            left: rect.left,
            right: window.innerWidth - rect.right,
            top: rect.top,
            bottom: window.innerHeight - rect.bottom
          });
        };

        updateBounds();
        window.addEventListener('resize', updateBounds);

        return () => window.removeEventListener('resize', updateBounds);
      }
      setTargetBounds(fullBounds);

      return undefined;
    }, [layerTarget]);

    let content = (
      <StyledContainer
        ref={ref || containerRef}
        id={id}
        full={full}
        margin={margin}
        modal={modal}
        targetBounds={!modal ? targetBounds : fullBounds}
        {...rest}
        position={position}
        plain={plain}
        responsive={responsive}
        background={background}
      >
        {children}
      </StyledContainer>
    );

    if (modal) {
      content = (
        <>
          <StyledOverlay
            plain={plain}
            onMouseDown={onClickOutside}
            responsive={responsive}
            style={{
              background
            }}
          />
          <StyledLayer
            ref={layerRef}
            id={id}
            targetBounds={targetBounds}
            plain={plain}
            position={position}
            responsive={responsive}
            tabIndex='-1'
          >
            {content}
          </StyledLayer>
        </>
      );
    }

    return content;
  }
);

LayerContainer.displayName = 'LayerContainer';

export { LayerContainer };
