import React, { type PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { cn } from '@superside/ui';
import { lazyRootAtom } from '@konsus/image';
import { useAtomMutator } from '@konsus/atoms';
import { Close } from '../../images/icons';
import { useResponsiveContext } from '../../providers';
import { Box, type BoxProps, RelativeBox } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Heading } from '../Heading';
import { Layer } from '../Layer';
import styles from './index.module.css';

const preventDefaultEventHandler = (e) => e.preventDefault();

export interface ModalProps {
  close?: () => void;
  header?: JSX.Element;
  headerText?: string;
  pad?: BoxProps['pad'];
  width?: BoxProps['width'];
  maxWidth?: string;
  margin?;
  position?;
  animation?;
  headerPad?: BoxProps['pad'];
  layerWidth?: string;
  layerMaxWidth?: string;
  layerMinHeight?: string;
  modal?;
  full?: boolean;
  fill?: BoxProps['fill'];
  background?: BoxProps['background'];
  disableMaxHeight?: boolean;
  disableHeader?: boolean;
  absoluteHeader?: boolean;
  responsive?: boolean;
  scrollableRef?: any;
  scrollLock?: boolean;
  elementId?: string;
  plain?: boolean;
  children?: React.ReactElement;
  className?: string;
}

const ScrollLockElement: React.FC = () => {
  return (
    <div>
      <style jsx global>
        {`
          body {
            overflow: hidden !important;
          }
        `}
      </style>
    </div>
  );
};

export const Modal: React.FC<PropsWithChildren<ModalProps>> = (props) => {
  const {
    pad = { horizontal: 'medium', top: 'small', bottom: 'medium' },
    width = 'medium',
    margin,
    layerWidth,
    layerMaxWidth,
    fill,
    full = false,
    disableMaxHeight = false,
    responsive = true,
    headerText,
    position,
    animation,
    headerPad,
    disableHeader,
    absoluteHeader = false,
    scrollableRef,
    background = 'rgba(0, 0, 0, 0.6)',
    modal,
    scrollLock = true,
    plain,
    elementId,
    className
  } = props;

  const setLazyRoot = useAtomMutator(lazyRootAtom);

  const size = useResponsiveContext();
  const contentRef = useRef(null);

  const setRef = useCallback(
    (ref) => {
      if (ref) {
        contentRef.current = ref;
        contentRef.current.ongesturestart = preventDefaultEventHandler;

        if (scrollableRef) {
          scrollableRef.current = ref;
        }

        setLazyRoot(scrollableRef);
      }
    },
    [scrollableRef]
  );

  const handleCloseEvent = useCallback(
    (e) => {
      if (e && e.isDefaultPrevented()) {
        return;
      }

      e && e.preventDefault();

      props.close && props.close();
    },
    [props.close]
  );

  useEffect(() => {
    return () => {
      if (contentRef.current) {
        contentRef.current.ongesturestart = null;
        contentRef.current = null;
      }
    };
  }, []);

  return (
    <>
      {scrollLock ? <ScrollLockElement /> : null}
      <Layer
        onClickOutside={handleCloseEvent}
        onEsc={handleCloseEvent}
        full={full}
        width={layerWidth}
        maxWidth={layerMaxWidth}
        responsive={responsive}
        margin={margin}
        position={position}
        animation={animation}
        disableMaxHeight={disableMaxHeight}
        background={background}
        modal={modal}
        plain={plain}
        style={{
          '--width-var-ffaa': props.width || 'unset',
          '--max-width-var-fe9e': props.maxWidth || 'unset'
        }}
        className={cn(
          !props.full && !props.disableMaxHeight ? 'max-h-[calc(100%_-_32px)]' : '',
          className
        )}
      >
        <div
          ref={setRef}
          style={{
            '--min-height-var-3da7': props.layerMinHeight || 'unset'
          }}
          className={`${styles.scrollableContainerStyle} min-h-[var(--min-height-var-3da7)] flex-[auto]`}
          id={elementId}
        >
          <Box
            pad={pad}
            fill={fill}
            width={responsive && size === 'small' ? 'full' : width}
            responsive={responsive}
          >
            {disableHeader ? null : (
              <ModalHeader
                text={headerText}
                pad={headerPad}
                absolute={absoluteHeader}
                close={handleCloseEvent}
              />
            )}
            {props.children}
          </Box>
        </div>
      </Layer>
    </>
  );
};

Modal.displayName = 'Modal';

export const ModalHeader = (props) => {
  const { text, close, ...rest } = props;

  const content =
    typeof text === 'string' ? (
      <Heading level='3' size='4'>
        {text}
      </Heading>
    ) : (
      text
    );

  return (
    <RelativeBox fill='horizontal'>
      <Box
        direction='row'
        fill='horizontal'
        justify={content ? 'between' : 'end'}
        align='center'
        margin={{ bottom: 'small' }}
        className={props.absolute ? 'absolute right-0 z-10 w-auto' : ''}
        {...rest}
      >
        <Box overflow='hidden' flex>
          {content}
        </Box>
        <ModalCloseButtonWrapper pad='xsmall'>
          <ModalCloseButton close={close} />
        </ModalCloseButtonWrapper>
      </Box>
    </RelativeBox>
  );
};

export const ModalCloseIcon = (props) => <Icon icon={Close} color='neutral-medium' {...props} />;

export const ModalCloseButton = (props) => (
  <Button
    plain
    clickArea
    icon={<ModalCloseIcon color={props.color} size={props.size} />}
    onClick={props.close}
    {...props}
  />
);

export const ModalCloseButtonWrapper = (props) => (
  <RelativeBox alignSelf='start' flex={false} {...props} />
);

export const ModalAbsoluteCloseButtonWrapper = (props) => (
  <Box margin='xsmall' pad='xsmall' className={'absolute right-0 top-0 z-[2]'} {...props} />
);
