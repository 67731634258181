export const useMaxWidthStyles = (props: any) => {
  if (props.maxWidth) {
    return {
      style: {
        '--max-w': props.maxWidth
      },
      className: 'max-w-[var(--max-w)]'
    };
  }

  return {};
};
